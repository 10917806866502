.avatarCover {
  margin-right: -4px;
}

.progressBarContainer {
  width: 140px;
}

.signatureContainer {
  width: 170px;
}

.active {
  background: rgba(94, 124, 213, 0.3);
}

.lastOpened {
  background-color: #a1a1a11c;
}
.selected {
  background-color: #edf0f9;
}

.cancelledRow {
  background-image: linear-gradient(
    -45deg,
    var(--warning32) 5.56%,
    var(--neutralWhite100) 5.56%,
    var(--neutralWhite100) 50%,
    var(--warning32) 50%,
    var(--warning32) 55.56%,
    var(--neutralWhite100) 55.56%,
    var(--neutralWhite100) 100%
  );
  background-size: 12.73px 12.73px;
  opacity: 60%;
}
