.tableSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  max-height: 80vh;
  overflow: scroll;
}

.tableHeader {
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  font-weight: 400;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
  align-items: flex-end;
  display: grid;
  padding: 4px 15px;
  letter-spacing: -0.03rem;
}

.tableHeaderMatchPackages {
  grid-template-columns: 15px 2fr 50px 100px 100px 3fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
  align-items: flex-start;
}

.packagesGroup,
.tableHeaderMatchPackages {
  align-items: flex-end;
}
.packagesGroup {
  display: grid;
  grid-template-columns: 1fr 90px 120px 26px;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
  padding-bottom: 0.25rem;
}
.tableHeaderMatchPackages .packagesGroup {
  padding-bottom: 0;
  align-items: flex-end;
}

.tableRowMatchPackages {
  grid-template-columns: 15px 2fr 50px 100px 100px 3fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
  align-items: flex-start;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}

.rowMissing {
  box-shadow: inset 0 0 0px 2px #ddd;
}
.rowSuccess {
  box-shadow: inset 0 0 0px 2px var(--Teal-6, #55bca6);
}
.rowWarning {
  box-shadow: inset 0 0 0px 2px var(--Yellow-5, #e4b700);
}
.rowDanger {
  box-shadow: inset 0 0 0px 2px var(--Red-5, #e15241);
}

.productName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.productNameContainer {
  /* width: 333px; */
}

.tableRow {
  display: grid;
  padding: 4px 10px;
}
.tableRow:hover {
  background: #edf0f9;
}
.tableRow:active /* on click */ {
  background: #d9e0f1;
}

.helperText {
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: 14px; /* 120% */
  letter-spacing: normal;
}

.inputBox {
  position: relative;
}

.quantityInput {
  height: 28px;
  font-size: 14px;
  font-weight: 700;
}

.packagesGroupPlaceholder {
  width: 100%;
  padding: 5px 10px;
  text-align: center;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #808080;
  margin-bottom: 0.25rem;
  scale: 1;
}

.packagesGroupPlaceholderDrag {
  width: 100%;
  padding: 5px 10px;
  text-align: center;
  border: 1.5px dashed rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #808080;
  margin-bottom: 0.25rem;
  scale: 1.03;
  height: 30px;
  transition: transform 0.2s, height 0.2s;
}
