.primary {
  color: var(--neutralWhite88);
  border: 1px solid var(--neutralWhite16);
  background: var(--transparent, rgba(255, 255, 255, 0.00));
}
.primary svg {
  fill: var(--neutralWhite88) !important;
}
.primary:hover {
  color: var(--deepPurple400);
  background: var(--deepPurple12);
}
.primary:hover svg {
  fill: var(--deepPurple400) !important;
}
.primary:active {
  color: var(--deepPurple400);
  background: var(--deepPurple12);
  border: 1px solid var(--deepPurple12);
}
.primary:active svg {
  fill: var(--deepPurple400) !important;
}

.primary-selected {
  color: var(--deepPurple800);
  background: var(--deepPurple200);
}
.primary-selected svg {
  fill: var(--deepPurple800) !important;
}
.primary-selected:hover {
  color: var(--deepPurple400);
  background: var(--deepPurple12);
}
.primary-selected:hover svg {
  fill: var(--deepPurple400) !important;
}
.primary-selected:active {
  color: var(--deepPurple400);
  background: var(--deepPurple12);
  border: 1px solid var(--deepPurple12);
}
.primary-selected:active svg {
  fill: var(--deepPurple400) !important;
}

.secondary {
  color: var(--neutralWhite88);
  border: 1px solid var(--neutralWhite16);
  background: var(--transparent, rgba(255, 255, 255, 0.00));
}
.secondary svg {
  fill: var(--neutralWhite88) !important;
}
.secondary:hover {
  color: var(--deepPurple400);
  background: var(--deepPurple12);
  border: 1px solid var(--neutralWhite24);
}
.secondary:hover svg {
  fill: var(--deepPurple400) !important;
}
.secondary:active {
  color: var(--deepPurple400);
  background: var(--deepPurple12);
  border: 1px solid var(--deepPurple12);
}
.secondary:active svg {
  fill: var(--deepPurple400) !important;
}

.secondary-selected {
  color: var(--deepPurple400);
  border: 1px solid var(--deepPurple400);
}
.secondary-selected svg {
  fill: var(--deepPurple400) !important;
}
.secondary-selected:hover {
  color: var(--deepPurple400);
  background: var(--deepPurple12);
  border: 1px solid var(--neutralWhite24);
} 
.secondary-selected:active {
  color: var(--deepPurple400);
  background: var(--deepPurple12);
  border: 1px solid var(--deepPurple12);
}

.disabled {
  color: var(--neutralWhite48);
  background: var(--neutralWhite4);
  cursor: not-allowed;
}
.disabled svg {
  fill: var(--neutralWhite48);
}
