.button {
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.small {
  height: 20px;
  width: 20px;
}

.medium {
  height: 26px;
  width: 26px;
}

/* VARIANT */
.deepPurple {
  background: var(--deep-purple-500);
}
.deepPurple:hover {
  background: var(--deep-purple-400);
}
.deepPurple:focus {
  background: var(--deep-purple-300);
}

.danger {
  background: var(--danger-600);
}
.danger:hover {
  background: var(--danger-500);
}
.danger:focus {
  background: var(--danger-400);
}

.disabledB {
  background: var(--neutral-black-24);
  opacity: 0.9;
}

.disabledW {
  background: var(--neutral-white-24);
}

.blackST {
  background: var(--neutral-black-4);
}
.blackST:hover {
  background: var(--neutral-black-7);
}
.blackST:focus {
  background: var(--neutral-black-12);
}

.black {
  background: var(--neutral-black-88);
}
.black:hover {
  background: var(--neutral-black-64);
}
.black:focus {
  background: var(--neutral-black-48);
}

.blackT {
  border-radius: 4px;
}
.blackT:hover {
  background: var(--neutral-black-4);
}
.blackT:focus {
  background: var(--neutral-black-7);
}

.outlineSuccessLight {
  border: 1px solid var(--neutral-black-20);
}
.outlineSuccessLight:hover {
  background: var(--success-12);
  border: 1px solid var(--neutral-black-20);
}
.outlineSuccessLight:focus {
  background: var(--success-400);
  border: 1px solid var(--neutral-black-20);
}
.outlineSuccessLight[disabled]:hover {
  background: var(--success-300);
  border: 1px solid var(--neutral-black-20);
}

.white {
  background: var(--neutral-white-100);
}
.white:hover {
  background: var(--neutral-white-76);
}
.white:focus {
  background: var(--neutral-white-64);
}

.whiteST {
  background: var(--neutral-white-16);
}
.whiteST:hover {
  background: var(--neutral-white-24);
}
.whiteST:focus {
  background: var(--neutral-white-32);
}

.whiteT {
  border-radius: 4px;
}
.whiteT:hover {
  background: var(--neutral-white-7);
}
.whiteT:focus {
  background: var(--neutral-white-12);
}

.outline {
  border: 1px solid var(--neutralBlack20);
}
.outline:hover {
  border: 1px solid var(--success400);
  background: var(--success200);
}
.outline:active {
  border: 1px solid var(--neutralBlack20);
  background: var(--success400);
}

.success {
  border: 1px solid var(--neutralBlack20);
  background: var(--success400);
}
.success:hover {
  border: 1px solid var(--success400);
  background: var(--success12);
}
.success:active {
  border: 1px solid var(--neutralBlack20);
}

.outlineSuccessDark {
  border: 1px solid var(--neutral-white-20);
}
.outlineSuccessDark:hover {
  background: var(--success-12);
  border: 1px solid var(--success-12);
}
.outlineSuccessDark:focus {
  background: var(--success-400);
  border: 1px solid var(--neutral-black-20);
}
.outlineSuccessDark[disabled]:hover {
  background: var(--success-32);
  border: 1px solid var(--neutral-black-20);
}
