.filterChip {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 1px 3px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  gap: 2px;
}
.filterChip img {
  display: block;
  height: 14px;
  width: 14px;
  min-width: 14px;
}

.filterCustomer {
  position: relative;
  color: #fff;
  cursor: pointer;
  background: var(--deep-purple-7, #6651c6);
}
.filterRetail {
  position: relative;
  color: #333;
  cursor: pointer;
  background: var(--Lime-6, #bbe749);
}
.filterDropshipping {
  position: relative;
  color: #333;
  cursor: pointer;
  background: var(--Turquoise-5, #01d6fb);
}

.fontStyle {
  font-style: italic;
}

.tableHeader {
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  font-weight: 400;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  width: 100%;
  min-width: 100%;
  height: auto;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 12px;
}

.tableRow:hover {
  background: #edf0f9;
}
.tableRow:active {
  background: #d9e0f1;
}

.tableRowOrderWithSignature,
.tableHeaderOrderWithSignature {
  display: grid;
  grid-template-columns: 15px 2fr 100px 40px 52px 36px 20px 0.7fr 0.9fr 0.2fr 0.7fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.tableRowOrderWithEditableDisplayPosition,
.tableHeaderOrderWithEditableDisplayPosition {
  display: grid;
  grid-template-columns: 45px 2fr 50px 40px 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.tableHeaderOrderSummary,
.tableRowOrderSummary {
  display: grid;
  grid-template-columns: 15px 2fr 50px 40px 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.tableRowOrder,
.tableHeaderOrder {
  display: grid;
  grid-template-columns: 15px 2fr 50px 40px 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.productName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.link {
  color: var(--blue-5, #2b8efa);
  font-size: 14px;
  font-weight: 500;
}
.link:hover {
  color: var(--blue-5, #2b8efa);
  opacity: 0.8;
}

.viesTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.tinySpinner {
  max-width: 26px;
  max-height: 26px;
}

.moreDataCounterOverride {
  position: relative;
  transform: translateY(-10%);
  width: max-content;
}

.showAllOrders {
  width: 280px;
}

.tableRowOrderForSales {
  padding-top: 4px;
  padding-bottom: 4px;
}

.editAmountWithoutTaxInput {
  max-height: 26px;
  display: flex;
  width: 108px;
  justify-content: flex-end;
}

.editPurchaseInvoicePositionInput {
  max-height: 18px;
  display: flex;
  justify-content: flex-end;
}

.editAmountWithoutTaxInput input {
  font-weight: 700;
}

.editPurchaseInvoicePositionInput input {
  font-weight: 700;
}

.manuallyEditedInvoice {
  margin-bottom: 1px;
}

.customTooltip {
  padding: 4px;
  height: 32px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.03rem;
}

.widerInput {
  width: 30px;
}

.editableItem {
  border-radius: 4px;
}

.editableItem:hover {
  cursor: pointer;
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}

.editableItem:focus {
  border: 1px solid var(--black-5, rgba(0, 0, 0, 0.05));
  background: var(--black-10, rgba(0, 0, 0, 0.1));
}

.formHeight {
  height: 28px;
  font-size: 14px;
}

.inputTextLabel {
  font-size: 11px;
  padding: 1px 2px;
}

.datePickerInput {
  max-height: 28px;
  font-weight: 700;
  max-width: 134px;
}

.datePickerPopup {
  z-index: 22;
}

.adjustedLineHeight {
  line-height: 22.4px;
}

.input {
  height: 28px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  margin-left: 14px;
}
