.typography {
  font-family: "Sofia Sans", sans-serif !important;
}

.noWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fs10 {
  font-size: 10px;
  line-height: 14px;
}
.fs12 {
  font-size: 12px;
  line-height: 18px;
}
.fs14 {
  font-size: 14px;
  line-height: 20px;
}
.fs16 {
  font-size: 16px;
  line-height: 22px;
}
.fs18 {
  font-size: 18px;
  line-height: 24px;
}
.fs20 {
  font-size: 20px;
  line-height: 28px;
}
.fs26 {
  font-size: 26px;
  line-height: 40px;
}
