.radioContainer {
  position: relative;
  align-items: start;
  display: inline-flex;
  cursor: pointer;
  gap: 8px;
}

.radioBase-sm {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.radioBase-md {
  height: 18px;
  width: 18px;
  border-radius: 50%;
}

.radioBase-light-not-checked {
  position: relative;
  background: transparent;
  border: 1px solid var(--neutralBlack20);
}

.radioBase-light-not-checked:hover {
  border: 1px solid var(--deepPurple200);
}
.radioBase-light-not-checked:active {
  border: 1px solid var(--neutralBlack20);
  box-shadow: 0px 0px 0px 3px #e2dcff, 0px 2px 3px 0px rgba(17, 12, 34, 0.12);
}

.radioBase-light-checked {
  position: relative;
  background: var(--deepPurple500);
}
.radioBase-light-checked:hover {
  box-shadow: 0px 0px 0px 4px rgba(226, 220, 255, 1), 0px 2px 4px 0px rgba(17, 12, 34, 0.12);
}
.radioBase-light-checked:active {
  background: var(--deepPurple500);
}

.radioBase-dark-not-checked {
  position: relative;
  background: transparent;
  border: 1px solid var(--neutralWhite20);
}
.radioBase-dark-not-checked:hover {
  border: 1px solid var(--deepPurple200);
}
.radioBase-dark-not-checked:active {
  border: 1px solid var(--neutralWhite20);
  box-shadow: 0px 0px 0px 3px #e2dcff, 0px 2px 3px 0px rgba(17, 12, 34, 0.12);
}

.radioBase-dark-checked {
  position: relative;
  background: var(--deepPurple500);
}
.radioBase-dark-checked:hover {
  box-shadow: 0px 0px 0px 4px rgba(134, 107, 255, 0.35), 0px 2px 4px 0px rgba(17, 12, 34, 0.12);
}
.radioBase-dark-checked:active {
  background: var(--deepPurple500);
}

.checkedRadioInnerDot-sm {
  background: var(--neutralWhite100);
  border-radius: 50%;
  height: 6px;
  position: absolute;
  width: 6px;
  left: 30%;
  top: 30%;
}

.checkedRadioInnerDot-md {
  background: var(--neutralWhite100);
  border-radius: 50%;
  height: 8px;
  position: absolute;
  width: 8px;
  left: 29%;
  top: 29%;
}

.disabled-light {
  background: var(--neutralBlack24);
  position: relative;
}

.disabled-dark {
  background: var(--neutralWhite24);
  position: relative;
}

.disabledInnerRectangle {
  background: var(--neutralBlack48);
  border-radius: 1px;
  height: 2px;
  position: absolute;
  top: 45%;
  width: 8px;
}

.disabledInnerRectangle-sm {
  left: 25%;
}

.disabledInnerRectangle-md {
  left: 29%;
}
