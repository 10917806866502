.wrapper {
  position: fixed;
  background: #333333;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px 4px 0px 0px;
  bottom: 11px;
  left: 450px;
  display: flex;
  padding: 8px 10px;
  height: 51px;
  align-items: center;
  justify-content: space-between;
}

.openWrapper {
  right: 10px;
}

.groupWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 13px;
  height: 13px;
  margin-right: 10px;
}

.number {
  font-size: 1.2rem;
  color: #fff;
}

.text {
  color: #bbb;
}
.group {
  cursor: pointer;
  margin-right: 15px;
}

.active {
  background: rgba(222, 225, 226, 0.2);
  font-weight: 700;
  border-radius: 3px;
  color: #937ef2;
}
.label {
  font-size: 10px;
  color: #bbb;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.label span {
  padding: 4px 3px;
  margin-left: 6px;
}
.label img {
  width: 15px;
  height: 15px;
}
.value {
  margin-left: 21px;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  font-weight: 700;
}

.packageIcon {
  width: 19px;
  margin: 0 5px;
}

.first {
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 12px;
}
.middle {
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin-top: 4.5px;
  width: 12px;
}
.last {
  border: 1px solid rgba(255, 255, 255, 0.9);
  margin-top: 3.5px;
  width: 12px;
}

.toggleIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.border {
  background-color: #000;
  height: 100%;
  width: 1px;
  margin: 0 10px;
}
