/* Dev mode */

.title {
  font-weight: bold;
  font-size: 16px;
  color: #808080;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}
.item {
  margin-bottom: 10px;
}
.label {
  font-size: 14px;
  margin-bottom: 10px;
}
.devModeWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  position: relative;
  height: 100%;
  background: #ffffff;
  border-width: 0px 1px;
  border-style: solid;
  border-color: rgba(50, 50, 50, 0.15);
  border-radius: 0px;
  font-size: 12px;
  margin-right: 12px;
}

.diode {
  width: 8px;
  height: 8px;
  background: #c2c2c2;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 6px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}
.diode.diodeRed {
  background: #d74e8a;
  box-shadow: 0 0 0 0 rgba(215, 78, 138, 1);
  animation: pulse-red 2s infinite;
}
.diode.diodeYellow {
  background: #ffe100;
  box-shadow: 0 0 0 0 rgb(255, 240, 28);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(215, 78, 138, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(215, 78, 138, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(215, 78, 138, 0);
  }
}

.diodeGreen {
  background: #01c9d5;
  box-shadow: 0px 0px 0px 3px rgba(1, 201, 213, 0.3);
}
.diode.diodeGreen {
  background: #01c9d5;
  box-shadow: 0 0 0 0 rgba(1, 201, 213, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(1, 201, 213, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(1, 201, 213, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(1, 201, 213, 0);
  }
}
.instance span {
  color: #aaa;
  margin-right: 4px;
}
.verticalDivider {
  height: 34px;
  width: 1px;
  background: rgba(0, 0, 0, 0.15);
  margin: 0 10px 0;
}
.devModeOn {
  box-shadow: 0 0 0 3px rgb(215 78 138);
}
.active {
  background: #01c9d5;
  color: #fff;
}
.active:hover {
  background: #00dce8 !important;
}
.active:focus {
  background: #00b4be !important;
}
/* Dev mode */
.mode {
  text-transform: lowercase;
  display: inline-block;
}

.mode::first-letter {
  text-transform: uppercase;
}
.modal {
  max-width: 700px;
  min-width: 700px;
  width: 700px;
  max-height: 90vh;
  overflow: auto;
}
.modalSmall {
  max-width: 550px;
  min-width: 550px;
  width: 550px;
  max-height: 90vh;
  overflow: auto;
}
.pre {
  word-wrap: brake-word;
  white-space: pre-wrap;
}
