.row {
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
  background: var(--neutralWhite100);
  padding: 0 15px;
}

.nestedRow {
  border-bottom: 1px dashed var(--gray-5, #e0e0e0);
}

.hoverable {
  cursor: pointer;
}

.hoverable:hover {
  background: #edf0f9;
}

.active {
  background: #d9e0f1;
}
