.collaborators {
  display: flex;
  align-items: flex-start !important;
}

.datePickerInput {
  max-height: 28px;
  font-weight: 700;
  max-width: 134px;
}

.timePickerInput {
  max-height: 28px;
}

.progressTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #000;
  line-height: 18px;
  letter-spacing: -0.015rem;
}

.rampSelect {
  height: 28px;
  font-size: 14px;
}

.cancelledSignature {
  text-decoration: line-through;
}

.timeContainer {
  width: 140px;
}

.formHeight {
  height: 28px;
  font-size: 14px;
  font-weight: 700;
}

.datePickerPopup {
  z-index: 22;
}

.input {
  height: 28px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  width: 184px;
}
