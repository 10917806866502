.tag {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex-shrink: 0;
  gap: 4px;
  height: 20px;
  justify-content: center;
  padding: 1px 4px;
}

.dark-deepPurple400-filled {
  background: var(--deepPurple400);
  box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
}
.dark-deepPurple400-filled:hover {
  background: var(--deepPurple300);
  box-shadow: 0px 2px 4px -2px rgba(17, 12, 34, 0.12);
}

.light-deepPurple400-filled {
  background: var(--deepPurple400);
  box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
}
.light-deepPurple400-filled:hover {
  background: var(--deepPurple300);
  box-shadow: 0px 2px 4px -2px rgba(17, 12, 34, 0.12);
}

.dark-deepPurple400-outlined {
  color: var(--deepPurple400) !important;
  border: 1px solid var(--deepPurple400);
  background: transparent;
  box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
}
.dark-deepPurple400-outlined:hover {
  color: var(--deepPurple500) !important;
  border: 1px solid var(--deepPurple500);
  background: var(--neutralWhite24);
  box-shadow: 0px 2px 4px -2px rgba(17, 12, 34, 0.12);
}

.light-deepPurple400-outlined {
  color: var(--deepPurple400) !important;
  border: 1px solid var(--deepPurple400);
  background: var(--neutralWhite100);
  box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
}
.light-deepPurple400-outlined:hover {
  color: var(--deepPurple500) !important;
  border: 1px solid var(--deepPurple500);
  background: var(--neutralWhite88);
  box-shadow: 0px 2px 4px -2px rgba(17, 12, 34, 0.12);
}

.dark-deepPurple50 {
  background: var(--deepPurple12);
}
.dark-deepPurple50:hover {
  background: var(--deepPurple32);
}

.light-deepPurple50 {
  background: var(--deepPurple50);
}
.light-deepPurple50:hover {
  background: var(--deepPurple75);
}

.dark-outline {
  border: 1px solid var(--neutralWhite16);
  background: var(--transparent, rgba(255, 255, 255, 0.00));
  box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
}
.dark-outline:hover {
  background: var(--neutralWhite24);
  box-shadow: 0px 2px 4px -2px rgba(17, 12, 34, 0.12);
}

.light-outline {
  border: 1px solid var(--neutralBlack7);
  background: var(--neutralWhite100);
  box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
}
.light-outline:hover {
  background: var(--neutralWhite88);
  box-shadow: 0px 2px 4px -2px rgba(17, 12, 34, 0.12);
}

.dark-quaternary-filled {
  background: var(--transparent, rgba(255, 255, 255, 0.00));
}
.dark-quaternary-filled:hover {
  background: var(--neutralWhite7);
}

.light-quaternary-filled {
  background: var(--transparent, rgba(255, 255, 255, 0.00));
}
.light-quaternary-filled:hover {
  background: var(--neutralBlack4);
}

.dark-quaternary-outlined {
  background: var(--transparent, rgba(255, 255, 255, 0.00));
  border: 1px solid var(--neutralWhite16);
}
.dark-quaternary-outlined:hover {
  background: var(--neutralWhite24);
  border: 1px solid var(--neutralWhite16);
}

.light-quaternary-outlined {
  background: var(--neutralWhite100);
  border: 1px solid var(--neutralBlack7);
}
.light-quaternary-outlined:hover {
  background: var(--neutralWhite88);
  border: 1px solid var(--neutralBlack7);
}

.dark-success-filled {
  background: var(--success12);
}
.dark-success-filled:hover {
  background: var(--success32);
}

.light-success-filled {
  background: var(--success50);
}
.light-success-filled:hover {
  background: var(--success75);
}

.dark-success-outlined {
  border: 1px solid var(--success500);
  background: transparent;
}
.dark-success-outlined:hover {
  border: 1px solid var(--success600);
  background: var(--neutralWhite24);
}

.light-success-outlined {
  border: 1px solid var(--success500);
  background: var(--neutralWhite100);
}
.light-success-outlined:hover {
  border: 1px solid var(--success600);
  background: var(--neutralWhite88);
}

.dark-danger-filled {
  background: var(--danger12);
}
.dark-danger-filled:hover {
  background: var(--danger32);
}

.light-danger-filled {
  background: var(--danger50);
}
.light-danger-filled:hover {
  background: var(--danger75);
}

.dark-danger-outlined {
  border: 1px solid var(--danger500);
  background: transparent;
}
.dark-danger-outlined:hover {
  border: 1px solid var(--danger600);
  background: var(--neutralWhite24);
}

.light-danger-outlined {
  border: 1px solid var(--danger500);
  background: var(--neutralWhite100);
}
.light-danger-outlined:hover {
  border: 1px solid var(--danger600);
  background: var(--neutralWhite88);
}

.dark-info-filled {
  background: var(--info12);
}
.dark-info-filled:hover {
  background: var(--info32);
}

.light-info-filled {
  background: var(--info50);
}
.light-info-filled:hover {
  background: var(--info75);
}

.dark-info-outlined {
  border: 1px solid var(--info500);
  background: transparent;
}
.dark-info-outlined:hover {
  border: 1px solid var(--info600);
  background: var(--neutralWhite24);
}

.light-info-outlined {
  background: var(--neutralWhite100);
  border: 1px solid var(--info500);
}
.light-info-outlined:hover {
  background: var(--neutralWhite88);
  border: 1px solid var(--info600);
}

.dark-warning-filled {
  background: var(--warning12);
}
.dark-warning-filled:hover {
  background: var(--warning32);
}

.light-warning-filled {
  background: var(--warning50);
}
.light-warning-filled:hover {
  background: var(--warning75);
}

.dark-warning-outlined {
  border: 1px solid var(--warning500);
  background: transparent;
}
.dark-warning-outlined:hover {
  border: 1px solid var(--warning600);
  background: var(--neutralWhite24);
}

.light-warning-outlined {
  border: 1px solid var(--warning500);
  background: var(--neutralWhite100);
}
.light-warning-outlined:hover {
  border: 1px solid var(--warning600);
  background: var(--neutralWhite88);
}

.dark-disabled {
  background: var(--neutralWhite4);
}

.light-disabled {
  background: var(--neutralBlack4);
}
