.modal {
  padding: 0;
  width: 75vw !important;
  height: 90vh;
}
.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  padding: 20px 15px;
}

.smsFiltersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: #fafafa;
}

.emailFiltersContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 15px;
  background: #fafafa;
}

.row {
  display: grid;
  grid-template-areas: "a a a a";
  grid-auto-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr);
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.header {
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #808080;
  border-bottom: none;
}

.ordersContainer {
  overflow: auto;
  max-height: 70vh;
  height: 100%;
}

.greyText {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;

  color: #808080;
}

.contact {
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.signature {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #333333;
}
.error {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #ce656b;
}
.error img {
  margin-right: 6px;
}

.noMessage {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: #808080;
}
.statusContainer {
  border-radius: 4px;
  padding: 8px 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.statusContainer img {
  margin-right: 6px;
}

.errorMsg {
  color: #ce656b;
  background: rgba(232, 112, 118, 0.35);
}

.awaiting {
  background: rgba(255, 150, 0, 0.35);
  color: #a76200;
}
.confirmed {
  background: rgba(0, 227, 240, 0.35);
  color: #1198b4;
}
.notSent {
  background: #dae3e6;
  color: #808080;
}
.errorBtn,
.awaitingBtn {
  margin-left: 6px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #333333;
  background: rgba(0, 0, 0, 0.05);
}

.message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.message span {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}
.conversationBtn {
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #765ee3;
}
.conversationBtn:hover {
  color: #9d87ff;
}
.date {
  font-style: italic;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #808080;
}

.name {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;

  color: #333333;
}

.rightPanel {
  position: absolute;
  right: 0;
  top: 0;
  width: 485px;
  height: 100%;
  z-index: 10;
  box-shadow: -2px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.input {
  background-color: transparent;
  outline: none;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  width: 30%;
}
.input:focus {
  border: 1px solid #765ee3;
}

.noContent img {
  margin-bottom: 20px;
}

.noContent div {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 6px;
}

.noContent span {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #808080;
}

.tooltip {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 1px;
  border-radius: 4px !important;
  margin-top: 2px !important;
  pointer-events: auto !important;
  opacity: 1 !important;
  padding: 0 !important;
  background-color: #808080 !important;
}
.tooltip:hover {
  visibility: visible !important;
}

.tooltip:global(.__react_component_tooltip.place-left)::before {
  top: 92% !important;
}
.tooltip:global(.__react_component_tooltip.place-left)::after {
  top: 92% !important;
}

.deadlineLabel {
  color: #e15241;
  font-size: 10px;
}

.inTimeLabel {
  color: #01c9d5;
  font-size: 10px;
}
