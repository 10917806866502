.checkMark {
  position: relative;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  width: 16px;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
}
.checkMark:not(:is(.checkMarkDisabled, .checkMarkActive, .checkMarkIndeterminate)) {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.container:hover .checkMark:not(:is(.checkMarkDisabled, .checkMarkActive)) {
  border: 1px solid var(--deepPurple200);
}

.container {
  position: relative;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
}

.checkMarkActive {
  background: var(--deepPurple500);
}

.container:hover .checkMarkActive:not(.checkMarkDisabled) {
  border: 1px solid var(--deepPurple500);
  box-shadow: 0px 0px 0px 3px #e2dcff, 0px 2px 3px 0px rgba(17, 12, 34, 0.12);
}

.indeterminate.disabled .checkMark {
  background: var(--neutralBlack48);
}
.checkMarkIndeterminate {
  background: var(--deepPurple200);
}
.checkMarkDisabled,
.disabled .checkMarkIndeterminate {
  background: var(--neutralBlack24);
}

.input {
  display: none;
  opacity: 0;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  min-height: 16px;
  height: 100%;
}
.mark,
.checkMark::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkMarkIndeterminate::after {
  content: "";
  width: 10px;
  border: solid var(--neutralBlack48);
  border-top-width: 2px;
  border-radius: 1px;
}

.active {
  top: -20px;
}

.disabled .checkMark:hover {
  cursor: not-allowed;
}
