.button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-style: normal;
  gap: 10px;
  justify-content: center;
  padding: 1px;
}

/* SIZE */
.small {
  border-radius: 4px;
  display: flex;
  gap: 6px;
  height: 26px;
  padding: 4px 8px;
}

.medium {
  border-radius: 6px;
  display: flex;
  gap: 10px;
  height: 30px;
  padding: 6px 12px;
}

.large {
  border-radius: 8px;
  display: flex;
  gap: 10px;
  height: 38px;
  padding: 8px 16px;
}

/* TEXT SIZE */
.smallText {
  line-height: 14px;
  text-transform: uppercase;
}

.mediumText {
  line-height: 18px;
}

.largeText {
  line-height: 22px;
}

/* VARIANT */
.light-deepPurple {
  background: var(--deepPurple500);
  color: var(--neutralWhite100);
}
.light-deepPurple:enabled:hover {
  background: var(--deepPurple400);
}
.light-deepPurple:active {
  background: var(--deepPurple300);
}

.dark-deepPurple {
  background: var(--deepPurple32);
  color: var(--deepPurple300);
}
.dark-deepPurple:enabled:hover {
  background: var(--deepPurple400);
  color: var(--neutralWhite100);
}
.dark-deepPurple:active {
  background: var(--deepPurple300);
  color: var(--neutralWhite100);
}

.light-danger {
  background: var(--danger600);
  color: var(--neutralWhite100);
}
.light-danger:enabled:hover {
  background: var(--danger500);
}
.light-danger:active {
  background: var(--danger400);
}

.dark-danger {
  background: var(--danger32);
  color: var(--danger400);
}
.dark-danger:enabled:hover {
  background: var(--danger500);
}
.dark-danger:active {
  background: var(--danger400);
}

.light-gray {
  background: var(--neutralBlack4);
  color: var(--neutralBlack88);
}
.light-gray:enabled:hover {
  background: var(--neutralBlack7);
}
.light-gray:active {
  background: var(--neutralBlack12);
}

.dark-gray {
  background: var(--neutralWhite16);
  color: var(--neutralWhite100);
}
.dark-gray:enabled:hover {
  background: var(--neutralWhite24);
}
.dark-gray:active {
  background: var(--neutralWhite32);
}


.light-black {
  color: var(--neutralWhite100);
  background: var(--neutralBlack88);
}
.light-black:enabled:hover {
  background: var(--neutralBlack64);
}
.light-black:active {
  background: var(--neutralBlack48);
}

.dark-black {
  background: var(--neutralWhite100);
  color: var(--neutralBlack100);
}
.dark-black:enabled:hover {
  background: var(--neutralWhite76);
}
.dark-black:active {
  background: var(--neutralWhite64);
}

.light-outline {
  color: var(--neutralBlack88);
  background: transparent;
  border: 1px solid var(--neutralBlack20, rgba(0, 0, 0, 0.2));
}
.light-outline:hover {
  background: var(--success200);
  border: 1px solid var(--success400);
}
.light-outline:active {
  color: var(--success900);
  background: var(--success400);
  border: 1px solid var(--success400);
}

.dark-outline {
  color: var(--success400);
  background: var(--neutralWhite20);
  border: 1px solid var(--neutralWhite20, rgba(255, 255, 255, 0.2));
}
.dark-outline:hover {
  background: var(--success32);
  border: 1px solid var(--success12, rgba(77, 223, 170, 0.12));
}
.dark-outline:active {
  color: var(--success900);
  border: 1px solid var(--success12, rgba(77, 223, 170, 0.12));
  background: var(--success400, #28ce92);
}

.light-success {
  color: var(--success900);
  background: var(--success400);
  border: 1px solid var(--success12);
}
.light-success:hover {
  color: var(--neutralBlack88);
  background: var(--success12);
  border: 1px solid var(--success400);
}
.light-success:active {
  color: var(--neutralBlack88);
  background: transparent;
  border: 1px solid var(--neutralBlack20);
}

.dark-success {
  background: var(--success400);
  color: var(--success400);
  border: 1px solid var(--success12);
}
.dark-success:hover {
  background: var(--success12);
  border: 1px solid var(--success400);
}
.dark-success:active {
  background: transparent;
  border: 1px solid var(--neutralWhite20);
}

.light-transparent {
  color: var(--neutralBlack100);
}
.light-transparent:enabled:hover {
  background: var(--neutralBlack4);
}
.light-transparent:active {
  background: var(--neutralBlack7);
}

.dark-transparent {
  color: var(--neutralWhite100);
}
.dark-transparent:enabled:hover {
  background: var(--neutralWhite7);
}
.dark-transparent:active {
  background: var(--neutralWhite12);
}

.light-disabled,
.light-disabled:hover,
.light-disabled:focus {
  background: var(--neutralBlack4);
  color: var(--neutralBlack48);
}
.dark-disabled,
.dark-disabled:hover,
.dark-disabled:focus {
  background: var(--neutralWhite4);
  color: var(--neutralWhite48);
}
