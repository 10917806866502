.pdfSection {
  display: grid;
  grid-template-rows: 42px 800px auto;
  font-family: "Sofia Sans", sans-serif;
  letter-spacing: -0.03rem;
}
.pdfViewer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  max-height: 90vh;
  overflow: scroll;
}
.pdfViewer img {
  display: block;
  max-width: 100%;
}
.optionList {
  position: absolute;
  z-index: 1;
  top: calc(100% + 4px);
  right: 0;
  min-width: 260px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  /* display: none; */
}
.chip {
  display: inline-flex;
  padding: 3px 0px 3px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
}
.groupSelect {
  display: flex;
  padding: 5px;
  align-items: center;
  align-content: center;
  gap: 4px;
  flex: 1 0 0;
  flex-wrap: wrap;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.approvalStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
}
.approvalStatus img {
  display: block;
  width: 16px;
  min-width: 16px;
}
.failureReason {
  padding-top: 3px 0;
  color: #8a0c03;
  font-size: 12px;
  height: 30px;
}
.failureReason div {
  text-overflow: ellipsis;
  width: 220px;
  overflow: hidden;
}
