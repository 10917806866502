.wrapper {
  border-bottom: 1px solid #ffffff1a;
  padding: 20px 20px 13px 20px;
  border-top: 1px solid #ffffff1a;
}

.label {
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

@media (min-width: 1520px) {
  .filtersBody {
    max-height: 80vh;
    height: 80vh;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer/Edge */
    scrollbar-color: transparent transparent;
    width: 100%;
  }
}

@media (max-width: 1520px) {
  .filtersBody {
    max-height: 73vh;
    height: 73vh;
    overflow: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer/Edge */
    scrollbar-color: transparent transparent;
    width: 100%;
  }
}

.filtersBody::-webkit-scrollbar {
  width: 0;
  display: none;
}
