.stickyPagination {
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.08);
  position: sticky;
  bottom: 0;
  z-index: 1;
  width: 100%;
  justify-content: flex-end;
}

.pagination {
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, 0.08);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  justify-content: flex-end;
}

.rightPanelPosition {
  width: calc(100% - 515px);  
}

@media (max-width: 1535px) {
  .rightPanelPosition {
    width: calc(100% - 485px);  
  }
}

@media (min-width: 1920px) {
  .rightPanelPosition {
    width: calc(100% - 585px);  
  }
}

.navigationBtn,
.optionBtn {
  width: 32px;
  min-width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  border-radius: 50%;
  background: none;
  border: none;
  position: relative;
}
.navigationBtn:hover,
.optionBtn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.pages {
  font-size: 0.81rem;
  color: #808080;
}
.pages strong {
  color: #333;
}
.navigationBtn {
  width: 26px;
  min-width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  border-radius: 50%;
  background: none;
  border: none;
}
.optionBtn img,
.navigationBtn img {
  display: block;
}
.listWrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: none;
}
.listConfiguration {
  box-shadow: 2px 5px 30px 4px rgba(0, 0, 0, 0.1), 1px 0px 6px 2px rgba(0, 0, 0, 0.1),
    -2px 4px 8px 0px rgba(0, 0, 0, 0.05);
  background: #fff;
  position: fixed;
  width: 380px;
  border-radius: 5px;
  min-height: 455px;
  bottom: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #e4e4e4;
  overflow: hidden;
}
.listConfigurationBtns {
  background: #fff;
  border-radius: 5px 5px 0 0;
}
.listConfiguration h4 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
  width: -webkit-fill-available;
  width: -moz-available;
}
.versionOption {
  display: block;
  position: static;
  margin-bottom: 0;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.95rem;
  user-select: none;
  border-radius: 4px;
  padding: 0;
  width: fit-content;
}
.versionOption span {
  display: inline-flex;
  width: fit-content;
  width: -moz-fit-content;
  font-size: 0.95rem;
  height: 28px;
  line-height: 28px;
  padding: 0 6px;
  background: #e4e4e4;
  border-radius: 5px;
  color: #333;
}
.versionOption input[type="radio"]:checked ~ span {
  background: #765ee3;
  color: #fff;
}
.versionOption input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.versionOption .versionImage {
  position: absolute;
  top: 155px;
  width: 100%;
  left: 0;
  display: none;
  background: #e4e4e4;
  border-radius: 0 0 5px 5px;
}
.versionOption .versionImage img {
  display: block;
  margin: 10px auto 0;
  width: 360px;
  height: auto;
  box-shadow: none;
  border: 0;
}
.versionOption input[type="radio"]:checked ~ .versionImage {
  display: block;
  box-shadow: none;
}
@media (max-width: 991px) {
  .button {
    width: 48px;
    height: 48px;
  }
}
.boxSpinner {
  width: 0 !important;
  height: 0 !important;
}
