.tooltip {
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 224px;
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  text-transform: none;
  z-index: 30;
  gap: 6px;
  font-family: "Sofia Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  box-shadow: 0px 6px 16px -6px rgba(17, 12, 34, 0.1);
}

.light {
  background: var(--neutral-black-88);
  color: var(--neutral-white-100);
}

.dark {
  background: var(--neutral-white-100);
  color: var(--neutral-black-100);
}
